input {
    border: 2px solid var( --clr-fg-alt);
    width: 290px;
    border-radius: 5px 5px;
    padding: 15px;
}

#email {
    margin-left: 17px;
}

textarea {
    width: 600px;
    height: 170px;
    border: 2px solid var( --clr-fg-alt);
    border-radius: 5px 5px;
    padding: 15px;
}

@media (max-width: 650px) {
    input {
        width: 200px;
    }
    textarea {
        width: 415px;
    }
  }

  @media (max-width: 480px) {
    input {
        width: 140px;
    }
    textarea {
        width: 300px;
    }
  }
