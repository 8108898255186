.skills__list {
    max-width: 650px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  
.skills__list-item {
    font-size: 30px;
    margin: 0.5em;
}
