.project {
    padding: 2em;
    margin: 0 auto;
    text-align: center;
    box-shadow: var(--shadow);
    transition: transform 0.2s linear;
}
  
.project:hover {
    transform: translateY(-7px);
}
  
.project__description {
    margin-top: 1em;
}

.project__img {
    margin-top: 20px;
    height: 250px;
    width: 100%;
    object-fit: contain;
}
  
.project__stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.2em 0;
}
  
.project__stack-item {
    margin: 0.5em;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--clr-fg-alt);
}
  
