.about {
    flex-direction: column;
    margin-top: 8em;
}
  
.about__name {
    color: var(--clr-primary);
}
  
.about__role {
    margin-top: 1.2em;
}

.about__img {
    height: 300px;
    width: 300px;
    border-radius: 50%;
}
  
.about__desc {
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 600px;
}
  
.about__desc,
.about__contact {
    margin-top: 2.4em;
}

/* typewriter animation */

.cursor {
    display: inline-block;
    position: relative;
}

.cursor::after {
    content: "";
    margin: auto;
    position: absolute;
    right: -4px;
    top: -3px;
    width: 1px;
    height: 100%;
    background-color: #666;
    animation: cursor-animation 1.5s step-end infinite;
}

@keyframes cursor-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}  

@media (max-width: 650px) {
    .about__desc {
        font-size: 0.9rem;
    }
    .about__img {
        height: 220px;
        width: 220px;
    }
}

@media (max-width: 480px) {
    .about__desc {
        font-size: 0.7rem;
    }
    .about__img {
        height: 180px;
        width: 180px;
    }
}

@media (max-width: 432px) {
    .about__desc {
        font-size: 0.6rem;
    }
    .about__img {
        height: 150px;
        width: 150px;
    }
}

