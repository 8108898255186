/* nav bar styling  */

.nav {
  position: fixed;
  background-color: #1d1d1f;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in;
  z-index: 10000;
}

/* main logo */

.nav .container {
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  max-height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  transition: all 0.3s ease-in;
}

/* other links in nav bar */

.nav ul {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
}

/* the links */

.nav a {
  color: #dadadd;
  text-decoration: none;
  padding: 7px 15px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

/* when the bar is active */

.nav.active {
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.nav.active a {
  color: #706f6f;
}

.nav.active .container {
  padding: 10px 0;
}

.nav a:hover {
  color: white;
}

.nav.active a:hover {
  color: black;
}

.logo__img {
  margin-top: -15px;
  margin-bottom: -15px; 
  max-height: 45px;
  max-width: 40px;
}

@media (max-width: 450px) {
  .logo__img {
    display: none;
  }
  li {
    font-size: 12px;
  }
  .nav .container {
    margin: 0;
  }
}