.projects__grid {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 525px 525px;
    grid-gap: 3em;
}

@media (max-width: 1100px) {
    .projects__grid  {
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
} 