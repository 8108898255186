.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #444;
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #0066CC;
  --clr-hover-link: #124980;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

main {
  max-width: 95%;
  overflow-x: hidden;
  margin: 0 auto;
}

.section {
  margin-top: 7em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0.8em 1.4em;
  position: relative;
}

.link:hover {
  color: var(--clr-hover-link);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-hover-link);
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

/* icons styling */

i {
  font-size: 40px;
}

/* general media queries */

@media (max-width: 650px) {
  h1 {
    font-size: 3.8rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  i {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 3.4rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  i {
    font-size: 27px;
  }
}

@media (max-width: 432px) {
  h1 {
    font-size: 3.0rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  i {
    font-size: 24px;
  }
}